.app {
    text-align: center;
}

.app__info-box {
    position: relative;
    top: 100px;    
}

.app__title {
    font-size: 200%;
}

.app__title-translation {
    font-size: 150%;
}

.app__link {    
    text-decoration: none;
    color: #555;
    transition: all 250ms ease;
}

.app__link:hover {
    color: #202020;
}

.app__link-title {    
    margin-top: 100px;
    font-size: 190%;
}

.app__link-title-translation {
    font-size: 140%;
}
